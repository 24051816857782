<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        to="/"
        class="brand-logo"
      >
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        v-if="!needSetData"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('general.welcome') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('account.titles.login.title') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
          >
            <b-tabs pills>
              <b-tab :title="$t('account.titles.login.byEmail')">
                <b-form-group
                  :label="$t('form.email.label')"
                  label-for="account-e-mail"
                >
                  <b-form-input
                    v-model="email"
                    name="email"
                    :state="errors && errors.email ? false : null"
                    :disabled="sentCode"
                    @keydown.enter.prevent="login"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.email">
                    {{ errors.email[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-tab>
              <b-tab :title="$t('account.titles.login.byPhone')">
                <b-form-group
                  :label="$t('form.phone.label')"
                  label-for="login-phone"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      class="text-monospace"
                    >
                      +38
                    </b-input-group-prepend>
                    <InputMask
                      id="login-phone"
                      v-model="phone"
                      mask="(999)999-99-99"
                      class="form-control"
                      :state="errors && errors.phone ? false : null"
                      name="login-phone"
                      :disabled="sentCode"
                      @keydown.enter.prevent="login"
                    />
                  </b-input-group>
                  <small
                    v-if="errors && errors.phone"
                    class="text-danger"
                  >{{ errors.phone[0] }}</small>
                </b-form-group>
              </b-tab>
            </b-tabs>

            <b-form-group v-if="sentCode">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <InputMask
                  id="login-code"
                  v-model="code"
                  mask="9-9-9-9"
                  class="form-control form-control-merge"
                  :state="errors && errors.code ? false : null"
                  name="login-code"
                  :placeholder="$t('form.code.label')"
                  @keydown.enter.prevent="login"
                />
              </b-input-group>
              <small
                v-if="errors && errors.code"
                class="text-danger"
              >{{ errors.code[0] }}</small>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              variant="primary"
              block
              @click="login"
            >
              {{ $t('general.send') }}
            </b-button>

            <b-row class="mt-2">
              <b-col
                cols="12"
                md="12"
                class="mt-1"
              >
                <b-button
                  variant="secondary"
                  class="tg-login-btn"
                  block
                  @click="socialLogin('telegram')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#29b6f6"
                      d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"
                    />
                    <path
                      fill="#fff"
                      d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"
                    />
                    <path
                      fill="#b0bec5"
                      d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"
                    />
                    <path
                      fill="#cfd8dc"
                      d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"
                    />
                  </svg>
                  Telegram
                </b-button>
              </b-col>
              <b-col
                cols="12"
                md="12"
                class="mt-1 hidden"
              >
                <b-button
                  variant="secondary"
                  class="gg-login-btn"
                  block
                  disabled
                  @click="socialLogin('google')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                  Google
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-col>

      <b-col
        v-else
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('general.welcome') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('account.titles.login.register') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
          >
            <b-form-group
              :label="$t('form.first_name.label')"
              label-for="account-first-name"
            >
              <b-form-input
                v-model="first_name"
                :placeholder="$t('form.first_name.label')"
                name="first_name"
                :state="errors && errors.first_name ? false : null"
                @keydown.enter.prevent="setName"
              />
              <b-form-invalid-feedback v-if="errors && errors.first_name">
                {{ errors.first_name[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="loginType !== 'phone'"
              :label="$t('form.phone.label')"
              label-for="login-phone"
            >
              <b-input-group>
                <b-input-group-prepend
                  is-text
                  class="text-monospace"
                >
                  +38
                </b-input-group-prepend>
                <InputMask
                  id="login-phone"
                  v-model="phone"
                  mask="(999)999-99-99"
                  class="form-control"
                  :state="errors && errors.phone ? false : null"
                  name="login-phone"
                  @keydown.enter.prevent="setName"
                />
              </b-input-group>
              <small
                v-if="errors && errors.phone"
                class="text-danger"
              >{{ errors.phone[0] }}</small>
            </b-form-group>

            <b-form-group
              v-else
              :label="$t('form.email.label')"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="email"
                name="email"
                :placeholder="$t('form.email.label')"
                :state="errors && errors.email ? false : null"
                @keydown.enter.prevent="setName"
              />
              <b-form-invalid-feedback v-if="errors && errors.email">
                {{ errors.email[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              variant="primary"
              block
              @click="setName"
            >
              {{ $t('account.titles.login.save') }}
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import GlobalMixin from '@/mixins/GlobalMixin'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
  },
  mixins: [GlobalMixin, togglePasswordVisibility],
  data() {
    return {
      title: process.env.TITLE,
      sentCode: false,
      phone: '',
      code: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      first_name: '',
      email: '',
      loginType: 'phone',
      needSetData: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      if (!this.sentCode) {
        this.$http.post('/api/phone/send', {
          phone: this.phone.replace(/[^0-9 ]/g, ''),
          email: this.email,
        })
          .then(() => {
            this.sentCode = true
          })
          .catch(error => {
            this.sentCode = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })

        return
      }

      useJwt.login({
        code: this.code.replace(/[^0-9 ]/g, ''),
        ref: this.$cookies.get('ref'),
      })
        .then(response => {
          const {
            data, token, localization, loginType,
          } = response.data

          useJwt.setToken(token)
          // useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(data))
          localStorage.setItem('localization', JSON.stringify(localization))

          this.$i18n.locale = localization.admin_language
          this.$i18n.fallbackLocale = localization.language

          this.$ability.update(data.permissions)

          if (data.first_name === data.phone || !data.phone) {
            this.needSetData = true
            this.loginType = loginType
            this.email = data.email
            this.phone = data.phone.length < 10 ? '' : data.phone
          } else {
            this.$router.replace(getHomeRouteForLoggedInUser())
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${this.$t('general.welcome')} ${data.name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: this.$t('administration.auth.login.successfully_logged'),
                  },
                })
                window.location.reload()
              })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          window.location.reload()
        })
    },
    setName() {
      this.$http.put('/api/profile', {
        first_name: this.first_name,
        email: this.email,
        phone: this.phone,
        notify_general: true,
        notify_promo: true,
      })
        .then(response => {
          localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(response.data.data))
          this.needSetData = false
          this.$emit('refetch-data')
          window.location.reload()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    socialLogin(driver = 'google') {
      if (driver === 'google') {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
          client_id: '485051396166-329kes0lncg7ogsjlgbsdf8kuepemsqg.apps.googleusercontent.com',
          ux_mode: 'popup',
          callback: response => {
            console.log('response', response)
            if (response.credential) {
              this.makeUser(driver, response.credential)
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.status,
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
            }
          },
        })
        // eslint-disable-next-line no-undef
        google.accounts.id.prompt()
      }
      if (driver === 'telegram') {
        this.makeUser(driver, this.uuidv4())
      }
    },
    async makeUser(driver, token) {
      try {
        await this.$http.post(`/api/social/${driver}/`, {
          token,
          ref: this.$cookies.get('ref'),
        })
          .then(response => {
            // eslint-disable-next-line no-shadow
            const { data } = response.data
            if (driver === 'telegram' && data.url && data.token) {
              window.location = data.url
            }
          })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: error.message,
            icon: 'AlertCircleIcon',
            variant: 'error',
          },
        })
      }
    },
    uuidv4() {
      // eslint-disable-next-line no-bitwise,no-mixed-operators
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
